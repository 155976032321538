import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import { HeroStyles, HeroContainer, Subtitle, Overlay } from "./Hero.styled";
import SocialIcons from "./SocialIcons";
const Hero = ({ activePage, data, image, isPost, isProyectos }) => {
  const isHome = activePage === "home";
  const isService = activePage === "servicio";
  const isBlog = activePage === "/blog" || activePage === "/blog/";
  const title = {
    home: "Mataseguros",
    servicio: data?.service?.title.es,
    ["/blog/"]: "Blog",
    ["/blog"]: "Blog",
  };
  const subtitle = {
    home: "Defendemos tus derechos",
    servicio: data?.service?.subtitle.es,
  };
  return (
    <HeroStyles
      isBlog={isBlog}
      isService={isService}
      isBlogPost={activePage === "/blog-example/"}
      image={image}
      isHome={isHome}
      isProyectos={isProyectos}
    >
      {!isHome && (
        <Overlay
          isService={isService}
          isProyectos={isProyectos}
          image={image}
        />
      )}
      <HeroContainer
        image={image}
        isBlog={isBlog}
        isService={isService}
        isProyectos={isProyectos}
        isHome={isHome}
      >
        {!isPost && (
          <>
            <h1>{title[activePage] || "Nosotros"}</h1>
            <Subtitle>{subtitle[activePage]}</Subtitle>
            <SocialIcons isService={isService} isBlog={isBlog} />
          </>
        )}
      </HeroContainer>
    </HeroStyles>
  );
};

export default Hero;
