import styled, { css, keyframes } from "styled-components";
import monuxImage from "../../assets/images/hero.png";
import { container } from "../../styles/GlobalStyles";
import { device, mediaQuery } from "../../styles/mixins";

export const Subtitle = styled.p`
  display: none;
  @media (min-width: 608px) {
    display: block;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  background-position: 50%;
  background-repeat: no-repeat;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: ${({ isService }) => isService && "0.3"};
  ${({ image, isProyectos }) =>
    image &&
    !isProyectos &&
    css`
      opacity: 0.5;
    `}
  background-size: cover;
  background-image: ${({ image }) => image && `url(${image})`}; ;
`;

export const HeroStyles = styled.div`
  color: #fff;
  position: relative;
  background-color: ${({ isHome }) =>
    !isHome ? "var(--primary)" : "rgb(192, 199, 203)"};
  height: ${({ isHome }) => (!isHome ? "420px" : "580px")};
  transition: all 0.5s ease;

  /* Case 1: Home */
  ${({ isHome }) =>
    isHome &&
    css`
      padding: 10vw 0 5vw;
      @media (max-width: 1200px) {
        height: 433px;
      }
    `}

  /* Case 3: Service */
  ${({ isService, isProyectos, isBlog }) =>
    (isService || isProyectos || isBlog) &&
    css`
      display: flex;
      align-items: center;
    `}
`;

export const HeroContainer = styled.div`
  ${({ isService, isProyectos, isBlog }) =>
    (isService || isProyectos || isBlog) &&
    css`
      ${container}
      margin-top: 100px;

      @media (max-width: 1328px) {
        align-items: ${isProyectos || isBlog ? "flex-end" : "center"};
        justify-content: center;
        /* h1,
        p {
          text-align: center !important;
        } */
      }

      @media (max-width: 360px) {
        ${(isProyectos || isBlog) &&
        css`
          align-items: center !important;
          h1,
          p {
            text-align: center !important;
            padding-left: 0 !important;
          }
        `}
      }
    `}

  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  align-items: flex-start;

  @media ${device.tablet} {
    text-align: right !important;
    align-items: flex-end;
  }

  /* Mobile */
  /* margin-top: 80px; */

  h1 {
    font-size: 6rem;
    font-size: ${({ isService }) =>
      `clamp(${isService ? "2rem" : "3rem"}, ${isService ? "4vw" : "7vw"}, ${
        isService ? "56px" : "6rem"
      })`};
    ${({ isProyectos, isBlog }) =>
      (isProyectos || isBlog) &&
      css`
        font-size: clamp(3rem, 12vw, 6rem);
      `}
    line-height: 1.2;
    margin-bottom: 0;
    max-width: ${({ isService }) => !isService && "70%"};
    color: ${({ isService, isBlog }) =>
      !isService && !isBlog && "var(--color__grey)"};
    padding-left: ${({ isService }) => !isService && "42px"};
    font-family: "EB Garamond", serif !important;
    font-weight: 500;
  }

  p {
    font-weight: 400;
    font-family: "EB Garamond", serif !important;
    line-height: 1.2;
    font-size: ${({ isService }) =>
      `clamp(1.6rem, ${isService ? "2.5vw" : "3vw"}, ${
        isService ? "36px" : "3rem"
      })`};
    color: var(--color__white);
    /* Small */
    margin-top: ${({ isService }) => (isService ? "18px" : "28px")};
    padding-left: 42px;
    max-width: ${({ isService }) => (isService ? "90%" : "80%")};

    /* mobileL */
  }

  /* Only for Home */
  ${({ isHome, isBlog }) =>
    isHome &&
    css`
      position: absolute;
      background-repeat: no-repeat;
      overflow: hidden;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgb(192, 199, 203);
      background-image: url(${monuxImage});
      background-size: 100vw 100%;  /* Full width and height */
      background-position: center;  /* Center the image */
      background-attachment: fixed; /* Optional: keeps the image in place on scroll */
      transition: all 0.5s ease;

      padding-top: 168px;
      /*
      padding-top: 168px;
      background-size: 664px;
      background-position: 18% 30%;
      transition: all 0.5s ease;

      background-size: 500px;
      background-position-x: calc(100% + 150px);

      @media (max-width: 1200px) {
        padding-top: 134px;
      }

      nav {
        padding-left: 24%;
      }
      */

      /* @media (max-width: 1100px) {
        background-position: 18% 21%;
      } */

      @media (min-width: 484px) {
        p {
          text-align: left;
          margin-top: 8px;
          max-width: 70%;
        }

        p {
          padding-left: 10%;
        }
        /*
        nav {
          padding-left: 10%;
        }
        */
      }

      @media (min-width: 474px) {
        h1 {
          padding-left: 10%;
        }
      }
      
      ${mediaQuery.sm`
      ${container}
      /*
      background-position: 18% 30%;
      */
  
      p {
        max-width: 50%;
        text-align: right;
      }
  
  
      
      `}
      /*
      ${mediaQuery.md`
      
        background-size: 500px;
      `}
      */
      ${mediaQuery.lg`
        /*
        background-size: ${isBlog ? "60%" : "70%"};
    
        background-position: -22% 30%;
        */
        p {
          align-self: flex-end;
        }
    
        h1 {
          max-width: 70%;
          align-self: flex-end;
        }
      `}
    `}
`;
